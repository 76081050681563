import {POST} from "@/api/request";

export const chat = (data) => {
    return POST('chat',data)
};
export const generationLlmAnswer = (data) => {
    return POST('/system/generationLlmAnswer',data)
};

export const generationLlmQuestionList = (data) => {
    return POST('/system/generationLlmQuestionList',data)
};

export const addQA = (data) => {
    return POST('/system/addQA',data)
};

export const reset = (data) => {
    return POST('/system/reset',data)
};
