import Store from "@/store";
import {buildUrl,initParams,bufferToBase64,bufferParams} from "@/js/XFSocketUtil";
import dayjs from "dayjs";

export default class XFSocket{

    asrSocket = null;
    onProcess = ()=>{};
    onComplete = ()=>{};
    onStatusChange = (status)=>{};

    _temp_str = "";
    _result_str = "";
    constructor() {
    }

    /**
     * 初始化链接
     * @returns {Promise<unknown>}
     */
    init(){
        let _this = this;
        return new Promise((resolve, reject)=>{
            if (Store.state.LOCK_STATUS){
                reject("不在时间范围内");
                return
            }

            _this.asrSocket = new WebSocket(buildUrl(Store.state.XF_API_KEY,Store.state.XF_API_SECRET));
            _this.asrSocket.onopen = (e)=>{
                try{
                    if(_this.onStatusChange){
                        _this.onStatusChange(true);
                    }
                    _this.asrSocket.send(JSON.stringify(initParams(Store.state.XF_APP_ID)));
                    resolve();
                }catch (err){
                    console.error(err);
                }
            };
            _this.asrSocket.onmessage = (e)=>{
                let jsonData = JSON.parse(e.data);
                if (jsonData.data && jsonData.data.result) {
                    let data = jsonData.data.result;
                    let string = "";

                    string = data.ws.map(w =>w.cw[0].w).join("");

                    if (data.pgs) {
                        // 将结果存储在resultTextTemp中
                        if (data.pgs === "apd") {
                            _this._temp_str = _this._result_str;
                        }
                        _this._result_str = _this._temp_str + string;
                    } else {
                        _this._result_str = _this._result_str + string;
                    }

                    _this._onProcess(_this._result_str,_this)
                    if (jsonData.code === 0 && jsonData.data.status === 2) {
                        _this._onComplete(_this._result_str,_this)
                    }
                    if (jsonData.code !== 0) {
                        _this.asrSocket.close();
                        console.error(jsonData);
                    }
                }
            };
            _this.asrSocket.onerror = (e) => {
                console.log("socket 断开");
                if(_this.onStatusChange){
                    _this.onStatusChange(false);
                }
            };
            _this.asrSocket.onclose = (e) => {
                console.log("socket 断开");
                if(_this.onStatusChange){
                    _this.onStatusChange(false);
                }
            };
        })
    }

    _onProcess(string,context){
        let _this = context;
        if(_this.onProcess){
            _this.onProcess(string);
        }
    }

    _onComplete(string,context){

        let _this = context;
        let splitChar = "";
        for (let i = 0; i < Store.state.XF_SPLIT_CHAR.length; i++) {
            let char = Store.state.XF_SPLIT_CHAR[i];
            let idx = string.indexOf(char);
            if(idx > -1){
                string = string.substring(0,idx + 1);
                splitChar = char;
                break;
            }
        }

        if(_this.onComplete){
            _this.onComplete(string);
        }

        if(string.length === 0){
            //没有结果，被主动断开
            setTimeout(function (){
                _this.init();
            },500);
        }

        _this._result_str = "";
        _this._temp_str = "";
        _this.asrSocket.close();
        _this.asrSocket = null;
    }

    send(isLastFrame,frameBuffer){
        if(this.asrSocket && this.asrSocket.readyState === this.asrSocket.OPEN){
            this.asrSocket.send(
                JSON.stringify(bufferParams(isLastFrame,frameBuffer))
            );
        }
    }

    close(){
        this.asrSocket.close();
    }

}