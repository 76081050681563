import Store from "@/store";
import dayjs from "dayjs";

export default class TimerClock{
    _timerClock = null;

    constructor() {

    }

    close(){
        if(this._timerClock){
            clearInterval(this._timerClock);
        }
    }
    start(){
        this._timerClock = setInterval(function (){
            let start = dayjs(`2023-01-01 ${Store.state.START_TIME}`,"yyyy-MM-dd HH:mm:ss");
            let end = dayjs(`2023-01-01 ${Store.state.END_TIME}`,"yyyy-MM-dd HH:mm:ss");
            let startHour = start.hour()
            let endHour = end.hour()
            let currentHour = dayjs().hour();

            if(currentHour >= startHour && currentHour < endHour){
                //开启中
                Store.commit("SET_LOCK_STATUS",false);
            }else {
                //关闭中
                Store.commit("SET_LOCK_STATUS",true);
            }
        },10000);
    }
}