<template>
  <div class="video-wrapper">
    <video id="speakVideo" class="video" :src="currentSrc" @error="playError" @loadeddata="loadeddata" @ended="startEnd" @play="startSpeak" @timeupdate="spenking"/>
    <video id="video" class="video" loop muted autoplay :src="staticUrl" @loadeddata="loadeddata"/>
    <canvas id="canvas" width="1080" height="1920"/>
  </div>
</template>

<script>

export default {
  name: 'Video',
  data(){
    return {
      video:null,
      speakVideo:null,
      canvas:null,
      ctx:null,
      staticUrl:'../video/stand.mp4',
      currentSrc:'',
      speak:false,
    }
  },
  mounted() {
    let _this = this;
    this.$nextTick(function (){
      _this.video = document.getElementById("video");
      _this.speakVideo = document.getElementById("speakVideo");
      _this.canvas = document.getElementById("canvas");
      _this.ctx = _this.canvas.getContext('2d');
    })
  },
  methods: {
    loadeddata(e) {
      let video = null;
      if(this.speak && this.speakVideo.currentTime > 0){
        video = this.speakVideo;
      }else {
        video = this.video;
      }
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
      this.ctx.drawImage(video, 0, 0, this.canvas.width, this.canvas.height);
      this.clearGreen();
      setTimeout(this.loadeddata,0)
    },

    playUrl(url){
      this.speakVideo.pause();
      let _this = this;
      this.$nextTick(function (){
        _this.currentSrc = url;
        _this.speakVideo.load();
        _this.$nextTick(function (){
          _this.speakVideo.play();
        })
      });
    },

    startSpeak(){
      this.$store.commit("SET_VIDEO_PLAYING",true);
    },
    spenking(e){
      this.speak = this.speakVideo.currentTime > 0;
    },
    startEnd(e){
      this.speak = false;
      this.$store.commit("SET_VIDEO_PLAYING",false);
      this.$emit('playEnd');
    },
    playError(e){
      if(this.currentSrc){
        this.$emit('playEnd');
      }
    },

    //基础版本去除绿幕
    clearGreen() {
      try {
        let frame = this.ctx.getImageData(0, 0, this.canvas.width, this.canvas.height);

        const pointLens = frame.data.length / 4;
        for (let i = 0; i < pointLens; i++) {
          let r = frame.data[i * 4];
          let g = frame.data[i * 4 + 1];
          let b = frame.data[i * 4 + 2];
          // console.log(r,g,b);
          // 判断如果rgb值在这个范围内则是绿幕背景，设置alpha值为0
          // 同理不同颜色的背景调整rgb的判断范围即可
          // if (r < 86 && g > 129 && b < 60) {
          if ((r < 114 && g < 255 && g > 100 && b < 100)) {
            frame.data[i * 4 + 3] = 0;
          }
        }

        // 重新绘制到canvas中显示
        this.ctx.putImageData(frame, 0, 0);

      } catch (e) {

      }
    },
  }
}
</script>

<style scoped>

.video-wrapper{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
#canvas{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.video{
  width: 100%;
  height: 100%;
  visibility: hidden;
  position: absolute;
}
</style>
