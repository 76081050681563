import CryptoJS from "@/js/crypto-js";

/**
 * 生成初始化链接
 * @param apiKey
 * @param apiSecret
 * @returns {string}
 */
export function buildUrl(apiKey,apiSecret){
    let url = "wss://iat-api.xfyun.cn/v2/iat";
    let host = "iat-api.xfyun.cn";
    let date = new Date().toGMTString();
    let algorithm = "hmac-sha256";
    let headers = "host date request-line";
    let signatureOrigin = `host: ${host}\ndate: ${date}\nGET /v2/iat HTTP/1.1`;
    let signatureSha = CryptoJS.HmacSHA256(signatureOrigin, apiSecret);
    let signature = CryptoJS.enc.Base64.stringify(signatureSha);
    let authorizationOrigin = `api_key="${apiKey}", algorithm="${algorithm}", headers="${headers}", signature="${signature}"`;
    let authorization = btoa(authorizationOrigin);
    return `${url}?authorization=${authorization}&date=${date}&host=${host}`;
}

/**
 * 生成初始化参数
 * @param appId
 */
export function initParams(appId) {
    return  {
        common: {
            app_id: appId,
        },
        business: {
            language: "zh_cn",
            domain: "iat",
            accent: "mandarin",
            vad_eos: 1000,
            dwa: "wpgs",
        },
        data: {
            status: 0,
            format: "audio/L16;rate=16000",
            encoding: "raw",
        },
    };
}

/**
 * 音频参数
 */
export function bufferParams(isLastFrame,frameBuffer){
    return {
        data: {
            status: isLastFrame?2:1,
            format: "audio/L16;rate=16000",
            encoding: "raw",
            audio: bufferToBase64(frameBuffer),
        },
    }
}

/**
 * buffre转base64
 * @param buffer
 * @returns {string}
 */
export function bufferToBase64(buffer){
    let binary = "";
    let bytes = new Uint8Array(buffer);
    let len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
}