<template>
  <div class="record-wrapper">
    <div class="status" :class="{'normal':asrSocketStatus}"></div>
    <div class="text-wrapper">
      <div class="question">{{question}}</div>
      <div class="answer">{{answer}}</div>
    </div>
  </div>
</template>

<script>

import RecorderManager from "@/js/recorder/index.esm";
import XFSocket from "@/js/XFSocket";
import TimerClock from "@/js/TimerClock";
import {chat} from "@/api/chat";
export default {
  name: 'App',
  data(){
    return {
      recordManager:null,
      question:'',
      answer:'',
      asrSocketStatus:false,
      xfSocket:null,
      timerClock:null
    }
  },

  mounted() {
    this.timerClock = new TimerClock();
    this.timerClock.start();
    this.initRecordManager();
    this.initXf();
  },
  beforeDestroy() {
    this.timerClock.close();
    this.recordManager.stop();
  },
  methods: {

    initRecordManager(){
      let _this = this;
      return new Promise((resolve, reject)=>{
        _this.recordManager = new RecorderManager("./processor");
        _this.recordManager.onFrameRecorded = ({ isLastFrame, frameBuffer }) => {
          if(_this.$store.state.VIDEO_PLAYING) return;
          _this.xfSocket.send(isLastFrame,frameBuffer);
        };
        _this.recordManager.start({
          sampleRate: 16000,
          frameSize: 1280,
        });
        resolve();
      })
    },

    initXf(){
      let _this = this;

      this.xfSocket = new XFSocket();
      this.xfSocket.init().then(()=>{
        console.log("成功开启Socket");
      }).catch(er=>{
        setTimeout(function (){
          _this.initXf();
          console.log("重试Socket");
        },10000);
      });
      this.xfSocket.onProcess = (msg)=>{
        _this.question = msg;
      };
      this.xfSocket.onComplete = (msg)=>{
        _this.question = msg;
        _this.getAnswers(msg);
      };
      this.xfSocket.onStatusChange = (status)=>{
        _this.asrSocketStatus = status;
      }
    },

    videoPlayEnd(){
      this.answer = "";
      this.question = "";
      this.initXf();
    },

    getAnswers(question){

      if(question.length === 0) return;

      let _this = this;
      chat({
        question: question
      }).then(res=>{
        let data = res.data;
        if(data.data){
          _this.answer = data.data.answer;
          _this.$emit("change",{
            question:question,
            answer:data.data
          });
          if(!data.data.videoUrl){
            _this.clearMessage();
          }
        }else {
          _this.answer = data.message;
          _this.clearMessage();
        }
      });
    },
    clearMessage(){
      let _this = this;
      setTimeout(function (){
        _this.videoPlayEnd();
      },2000)
    }
  }
}
</script>

<style scoped>

.record-wrapper{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.status{
  position: fixed;
  top: 6px;
  left: 6px;
  height: 8px;
  width: 8px;
  background: orangered;
  border-radius: 100%;
}
.status.normal{
  background: mediumspringgreen !important;
}
.text-wrapper{
  font-size: 40px;
  line-height: 1.2;
  position: absolute;
  bottom: 20px;
  left: 5%;
  width: 90%;
  text-align: center;
}

.question{
  color: white;
  font-weight: bold;
}
.answer{
  color: chocolate;
  min-height: 200px;;
}

</style>
