<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>

import VideoManager from "@/components/VideoManager.vue";
import RecordManager from "@/components/RecordManager.vue";
export default {
  name: 'App',
  components:{
    VideoManager,RecordManager
  },
  data(){
    return {

    }
  },
  mounted() {


    document.addEventListener('keydown', this.keyDown);
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.keyDown);
  },
  methods: {


    keyDown(e) {
      if (e.metaKey || e.shiftKey) return;

      if(e.target !== document.body) return;

      e.stopPropagation();
      e.preventDefault();

      if (e.key === 'Escape' || e.keyCode === 27) {
        //返回按钮
        this.$router.replace("/");
      } else if (e.key === 'e' || e.keyCode === 69) {
        //e键
        if (this.$route.name !== 'learn') {
          this.$router.replace("learn");
        }
      }
    }
  }
}
</script>

<style>
html,body{
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  transform-origin: 0 0;
  background-image: url("./assets/07.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  position: relative;
}
</style>
