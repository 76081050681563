import axios from 'axios'

// 创建axios实例
const request = axios.create({
    baseURL: "https://xuniren.dmpp.net.cn/api",
    headers:{
        'Content-Type': 'application/json'
    },
})
export function POST(url,data){
    return request.post(url,data)
}

