import Vue from "vue"
import Vuex from 'vuex'
Vue.use(Vuex)

export default new Vuex.Store({
	state:{
		XF_APP_ID : "224bde7f",
		XF_API_KEY : "4850b7341f59c8b1323f65597b42c4c9",
		XF_API_SECRET : "NDNhZDJmNGRiMmUzYzBkYjE4YmY4Y2Ew",
		XF_SPLIT_CHAR : ["？","。"],
		VIDEO_PLAYING:false,
		START_TIME:'07:00:00',
		END_TIME:'23:00:30',
		LOCK_STATUS:false,
	},
	mutations:{
		SET_VIDEO_PLAYING(state,value){
			state.VIDEO_PLAYING = value;
		},
		SET_LOCK_STATUS(state,value){
			state.LOCK_STATUS = value;
		}
	},
	actions:{
		
	},
	getters:{
		
	}
})